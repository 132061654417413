import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import PcCardUtil,{IPcCardDataObj} from './pcCardUtil';
import SplitOrderList from "@/views/project/order/order/xdgl/splitOrder/SplitOrderList.vue";
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
export default defineComponent({
    name:'PcCard',
    title:'排产',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IPcCardDataObj=reactive<IPcCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    pcDetail:{},
                },
                details:['pcDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/pc')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                // code: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.code_rule'))],
                sjpcrq: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.sjpcrq_rule'))],
                yjrkrq: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.yjrkrq_rule'))],
                sjrkrq: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.sjrkrq_rule'))],
                scjd: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.scjd_rule'))],
                oaFlag: [utils.UtilPub.commonValidRule(proxy.$t('pc.form.oaFlag_rule'))],
            },
            otherParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PcCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'scjd'==params.comboId){
                    return [{value:0,label:'含谷基地'},{value:1,label:'涪陵基地'}]
                }
                if(params && 'oaFlag'==params.comboId){
                    return [{value:0,label:'否'},{value:1,label:'是'}]
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'排产明细',
                        readOnly:dataObj.disabled,
                        toolBar: [{
                            id: "chooseSplitOrder",
                            title: "选择小单",
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseSplitOrder',
                            param:{}
                        }],
                        canAdd: false,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/pc/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:any,selectId:string)=>{
            if('scjd'==selectId){}
            if('oaFlag'==selectId){
                if(newValue==0)dataObj.form.oaTime='';
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{

        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('pcDetail'==options.detailType){
            }
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return dataObj.utilInst.beforeSave();
        }
        //选择订单按钮事件
        const chooseSplitOrder=async ()=>{
            await utils.UtilPub.openDialog({
                proxy:proxy,modelComp:SplitOrderList,ownerComp:proxy,
                from:'PcCard',overflow:'hidden',title: '选择小单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            dataObj.utilInst.dealSplitOrder(selectRows);
            return true;
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:proxy,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_SPLIT_ORDER_CODE,
                cardFrom:'SplitOrderList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler
         ,chooseSplitOrder,sureHandler,orderDetail
        }
    }
});