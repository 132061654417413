import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2c6f358a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Pc" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-left":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_EditTableItem = _resolveComponent("EditTableItem")!
  const _component_EditTable = _resolveComponent("EditTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "120px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 2
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.code'),
                      prop: "code"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[0] || (_cache[0] = e => _ctx.form.code = _ctx.valid(e)),
                          modelValue: _ctx.form.code,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.code) = $event)),
                          placeholder: "排产编号自动生产",
                          maxlength: "20",
                          disabled: true,
                          clearable: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.sjpcrq'),
                      prop: "sjpcrq"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.sjpcrq,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.sjpcrq) = $event)),
                          placeholder: _ctx.$t('pc.form.sjpcrq_placeholder'),
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.yjrkrq'),
                      prop: "yjrkrq"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.yjrkrq,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.yjrkrq) = $event)),
                          placeholder: _ctx.$t('pc.form.yjrkrq_placeholder'),
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.sjrkrq'),
                      prop: "sjrkrq"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.sjrkrq,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.sjrkrq) = $event)),
                          placeholder: _ctx.$t('pc.form.sjrkrq_placeholder'),
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.kfyrq'),
                      prop: "kfyrq"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.kfyrq,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.kfyrq) = $event)),
                          placeholder: _ctx.$t('pc.form.kfyrq_placeholder'),
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.scjd'),
                      prop: "scjd"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('scjd',el),
                          modelValue: _ctx.form.scjd,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.scjd) = $event)),
                          clearable: "",
                          placeholder: _ctx.$t('pc.form.scjd_placeholder'),
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[7] || (_cache[7] = (val)=>{_ctx.selectOnChange(val,'scjd')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'scjd'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.oaFlag'),
                      prop: "oaFlag"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('oaFlag',el),
                          modelValue: _ctx.form.oaFlag,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.oaFlag) = $event)),
                          clearable: "",
                          placeholder: _ctx.$t('pc.form.oaFlag_placeholder'),
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[9] || (_cache[9] = (val)=>{_ctx.selectOnChange(val,'oaFlag')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'oaFlag'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('pc.form.oaTime'),
                      prop: "oaTime"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.oaTime,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.oaTime) = $event)),
                          placeholder: _ctx.$t('pc.form.oaTime_placeholder'),
                          disabled: _ctx.disabled || _ctx.form.oaFlag==0
                        }, null, 8, ["modelValue", "placeholder", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_EditTable, _mergeProps({
      ref: el=>_ctx.refMap.set('pcDetail',el)
    }, _ctx.cardDetailParam('pcDetail'), {
      beganEdit: _ctx.beganEdit,
      beforeEndEdit: _ctx.beforeEndEdit
    }), {
      tbCols: _withCtx(() => [
        _createVNode(_component_EditTableItem, {
          ownerInst: this,
          editTableItems: _ctx.compParams.detailInfos.pcDetail.columns,
          refMap: _ctx.refMap
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "F_SPLIT_ORDER_CODE",
              label: "小单号",
              width: "150"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_tag, {
                  onClick: ($event: any) => (_ctx.orderDetail(scope.row)),
                  type: "info",
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.row.F_SPLIT_ORDER_CODE), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "F_ZONE_NAME",
              label: "销售区域"
            }),
            _createVNode(_component_el_table_column, {
              prop: "F_XS_NAME",
              label: "销售经理"
            }),
            _createVNode(_component_el_table_column, {
              prop: "F_COUNTRY_NAME",
              label: "出口国家"
            }),
            _createVNode(_component_el_table_column, {
              prop: "F_DZZY",
              label: "单证专员",
              width: "130"
            }),
            _createVNode(_component_el_table_column, {
              label: "本次入库数量",
              prop: "F_SJRKSL"
            }, {
              default: _withCtx((scope) => [
                (!scope.row.EDIT)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row.F_SJRKSL), 1))
                  : (_openBlock(), _createBlock(_component_el_input_number, {
                      key: 1,
                      modelValue: scope.row.F_SJRKSL,
                      "onUpdate:modelValue": ($event: any) => ((scope.row.F_SJRKSL) = $event),
                      min: 0,
                      max: scope.row.F_MAX_NUM,
                      style: {"width":"100%"}
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "max"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["editTableItems", "refMap"])
      ]),
      _: 1
    }, 16, ["beganEdit", "beforeEndEdit"])
  ]))
}