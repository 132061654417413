import BaseBean from "@/utils/BaseBean";
import _ from "lodash";

export interface IPcCardDataObj {
    utilInst:PcCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class PcCardUtil extends BaseBean{
    public dataObj:IPcCardDataObj;

    constructor(proxy:any,dataObj:IPcCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
    //处理选择的小单
    public dealSplitOrder(selectRows:any):void{
        let existRows=this.dataObj.refMap.get('pcDetail').getTbInst().tbData;
        let bakData = _.cloneDeep(existRows);
        selectRows.forEach((row:any)=>{
            // if(this.checkExist(bakData,row.F_SPLIT_ORDER_CODE)==-1) {
                row.F_SPLIT_ORDER_ID=row.F_ID;
                delete row.F_ID;//删除F_ID，否则addRow的时候辉往更新数组里面添加
                /**
                 * 这里出了一个问题，找了好久才找到这里，问题如下:
                 * 从弹出框选择第n跳记录添加到当前明细表格中，顺序号加入是第1行，问题来了，在当前明细表中，rowIndex本来该为0，结果rowIndex却是n，
                 * 在editTableUtil的77行点击行获取rowindex的时候就会报错，所以就必须把rowIndex删除，删除了之后，rowindex会在
                 * :row-class-name那里添加上，可是遇到一个很怪的问题，我即便这样做了，但是rowindex还是以前的，所以我才用下发深复制一份，这样rowindex就改变了
                 * */
                delete row.rowIndex;
                let tempRow = _.cloneDeep(row);
                tempRow.F_MAX_NUM=row.F_NUM-row.F_IN_NUM;//本次可以入库的最大数量,用作控件限制输入用,可惜只能新增的时候用，编辑的时候就不能限制了
                tempRow.F_SJRKSL=row.F_NUM-row.F_IN_NUM;//默认填入本次可以入库的最大数量
                this.dataObj.refMap.get('pcDetail').addRow(tempRow);
            // }
        })
    }
    public checkExist(existRows:any,addSplitOrderCode:string):number{
        return existRows.findIndex((item:any)=>item.F_SPLIT_ORDER_CODE==addSplitOrderCode);
    }
    //保存之前验证
    public beforeSave():boolean{
        if(this.dataObj.form.oaFlag==1 && !this.dataObj.form.oaTime){
            this.proxy.$message('请选择OA下单时间');
            return false;
        }
        //验证明细表格数据是否合法
        let pcDetailData=this.dataObj.refMap.get('pcDetail').getDetailData();
        if(pcDetailData.length==0){
            this.proxy.$message('排产明细不能为空');
            return false;
        }
        // for(let i=0;i<pcDetailData.length;i++){
        //     if(pcDetailData[i]['F_SJRKSL']==0){
        //         this.proxy.$message({type:'error',message:'第'+(i+1)+'行，本次入库数量不能为0'});
        //         return false;
        //     }
        // }
        return true;
    }
}